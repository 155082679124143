<template>
  <div class="video">
    <!-- 视频播放 -->
      <!-- <video style="width:100%;" controls = "true" src="https://finder.video.qq.com/251/20302/stodownload?encfilekey=Cvvj5Ix3eewK0tHtibORqcsqchXNh0Gf3sJcaYqC2rQAPKGEicQMZejmWMx1WaGdNBoZic70lUuhcEyXPAf3H8uIJGicPeFpmVRymjCpCb3l8oyiapKiaAYiafI7sz63RagYum4&token=x5Y29zUxcibBmTFvyMEIaNAxib4j91vEhQfvTc7Zfdz9LrtzlrpSxicJAk9UleFllOIeVn3vAt8hRs&idx=1&adaptivelytrans=0&bizid=1023&dotrans=3071&hy=SH&m="></video> -->
    <div class="lists" v-if="datas[0]" @click="noticedetail(datas[0])">
      <div class="images">
        <img class="imasd" :src="datas[0].cover" alt="" />
        <!-- <video class="imasd" style="width:100%;" :src="item.url"></video> -->
      </div>
      <div class="sizes">
        <div class="tit_p">{{ datas[0].title }}</div>
        <div class="com_p">{{ datas[0].time }}</div>
      </div>
    </div>
    <div class="lists" v-if="datas[1]" @click="noticedetail(datas[1])">
      <div class="images">
        <img class="imasd" :src="datas[1].cover" alt="" />
        <!-- <video class="imasd" style="width:100%;" :src="item.url"></video> -->
      </div>
      <div class="sizes">
        <div class="tit_p">{{ datas[1].title }}</div>
        <div class="com_p">{{ datas[1].time }}</div>
      </div>
    </div>
    <div class="lists" v-if="datas[2]" @click="noticedetail(datas[2])">
      <div class="images">
        <img class="imasd" :src="datas[2].cover" alt="" />
        <!-- <video class="imasd" style="width:100%;" :src="item.url"></video> -->
      </div>
      <div class="sizes">
        <div class="tit_p">{{ datas[2].title }}</div>
        <div class="com_p">{{ datas[2].time }}</div>
      </div>
    </div>
    <div class="zwgd" @click="ckgd()">查看更多</div>
  </div>
</template>

<script>
import {
  Button,
  Dialog,
  Icon,
  Toast,
  Field,
  Tabbar,
  TabbarItem,
  NoticeBar,
} from "vant";
import { notice } from "@/network/index.js";
export default {
  components: {
    [Button.name]: Button,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [NoticeBar.name]: NoticeBar,
    [Toast.name]: Toast,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      datas: [],
    };
  },
  created() {},
  mounted() {
    this.getlist();
  },

  methods: {
    // 文章列表
    getlist() {
      let userid = sessionStorage.getItem("id");
      let data = {
        userid,
        moduleid: 12,
      };
      notice(data).then((res) => {
        this.datas = res.data.data;
      });
    },
    // 跳转到文章详情
    noticedetail(e){
      // console.log(e)
      if(e.url==null||e.url==""){
        this.$router.push('/noticedetail?id='+e.id)
      }else{
        // console.log(e.url)
        window.location.href =e.url
        // Toast("跳转到"+e.url)
      }
    },
    // 查看更多
    ckgd() {
      this.$router.push('/ckgd?id=2')
    },
  },
};
</script>

<style scoped lang='scss'>
.video {
  width: 100%;
  .lists {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .images {
      width: 35%;
      height: 80px;
      .imasd {
        width: 100%;
        height: 100%;
      }
    }
    .sizes {
      width: 63%;
      height: 80px;
      position: relative;
      .tit_p {
        width: 100%;
        font-size: 18px;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .com_p {
        position: absolute;
        bottom: 0;
        font-size: 12px;
        color: rgb(158, 158, 158);
      }
    }
  }
  .zwgd {
    text-align: center;
    font-size: 14px;
    color: rgb(83, 83, 83);
    margin-top: 20px;
  }
}
</style>