<template>
  <div class="polocy">
    <div class="lists" @click="noticedetail(datas[0])">
      <div class="images">
        <img :src="datas[0].cover" alt="" />
      </div>
      <div class="sizes">
          <div class="tit_p">{{datas[0].title}}</div>
          <div class="com_p">{{datas[0].time}} e证e卡</div>
      </div>
    </div>
    <div class="lists" @click="noticedetail(datas[1])">
      <div class="images">
        <img :src="datas[1].cover" alt="" />
      </div>
      <div class="sizes">
          <div class="tit_p">{{datas[1].title}}</div>
          <div class="com_p">{{datas[1].time}} e证e卡</div>
      </div>
    </div>
    <div class="lists" @click="noticedetail(datas[2])">
      <div class="images">
        <img :src="datas[2].cover" alt="" />
      </div>
      <div class="sizes">
          <div class="tit_p">{{datas[2].title}}</div>
          <div class="com_p">{{datas[2].time}} e证e卡</div>
      </div>
    </div>
    <div class="zwgd" @click="ckgd()">查看更多</div>
  </div>
</template>

<script>
import {
  Button,
  Dialog,
  Icon,
  Toast,
  Field,
  Tabbar,
  TabbarItem,
  NoticeBar,
} from "vant";
import { notice } from "@/network/index.js";
export default {
  components: {
    [Button.name]: Button,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [NoticeBar.name]: NoticeBar,
    [Toast.name]: Toast,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
        datas:[]
    };
  },
  created() {},
  mounted() {
    this.getlist()
  },

  methods: {
    // 文章列表
    getlist(){
      let userid = sessionStorage.getItem("id");
      let data={
        userid,
        moduleid:13,
      }
      notice(data).then(res=>{
        console.log(res)
        this.datas=res.data.data
      })
    },
    // 跳转到文章详情
    noticedetail(e){
      console.log(e)
      if(e.url==null||e.url==""){
        this.$router.push('/noticedetail?id='+e.id)
      }else{
        window.location.href =e.url
      }
    },
    // 查看更多
    ckgd(){
      this.$router.push('/ckgd?id=1')
    },
  },
};
</script>

<style scoped lang='scss'>
.polocy {
  width: 100%;
  .lists {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .images {
      width: 35%;
      height: 80px;
      border-radius: 4px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .sizes {
      width: 63%;
      height: 80px;
      position: relative;
      .tit_p{
        width: 100%;
        font-size: 18px;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .com_p{
          position: absolute;
          bottom: 0;
          font-size: 12px;
          color: rgb(158, 158, 158);
      }
    }
  }
  .zwgd{
      text-align: center;
      font-size: 14px;
      color: rgb(83, 83, 83);
      margin-top: 20px;
      padding-bottom: 20px;
  }
}
</style>