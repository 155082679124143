<template>
  <div class="meright">
    <!-- 顶部导航 -->
    <!-- <titleNav title="登录" :left-arrow="true" left-text="" /> -->
    <!-- 内容 -->
    <div class="bodycom_r">
      <div class="logintop">
        <div class="bsa">
          <!-- <div class="topone_r" @click="login()">登录/注册</div> -->
          <div
            class="topone_r"
            v-if="login == 'true' ? false : true"
            @click="loginss()"
          >
            登录/注册
          </div>
          <div class="topone_r" v-else>
            {{ phone }}
          </div>
          <div class="toptwo_r">欢迎使用社保小助手</div>
        </div>
      </div>
      <div class="body_r">
        <div class="order_r" @click="lhjyorder()">
          <div class="order_l">灵活就业订单</div>
          <div class="order_r"><van-icon name="arrow" /></div>
        </div>
        <div class="order_r" @click="zyjnorder()">
          <div class="order_l">职业技能订单</div>
          <div class="order_r"><van-icon name="arrow" /></div>
        </div>
        <div class="order_r">
          <div class="order_l">联系我们</div>
          <div class="order_r">
            <span @click="callPhone(400 - 8877 - 827)">400-8877-827</span>
          </div>
        </div>
        <div class="order_r">
          <div class="order_l">版本号</div>
          <div class="order_r">V0.0.1</div>
        </div>
        <div class="order_r" v-if="login == 'true' ? true : false">
          <div class="order_l">退出登录</div>
          <div class="order_r" @click="signout()" style="color: red">退出</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titleNav from "@/components/conetnts/titleNav.vue";
// import floatBtn from "@/components/floatBtn.vue";
import { Button, Dialog, Icon, Toast, Field, Tabbar, TabbarItem } from "vant";
import { banner, price, indexs, info } from "@/network/index.js";
export default {
  components: {
    titleNav,
    [Button.name]: Button,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [Toast.name]: Toast,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      login: "false",
      phone: "",
    };
  },
  created() {},
  mounted() {
    this.infoget();
    this.login = localStorage.getItem("login");
  },
  methods: {
    infoget() {
      let userid = sessionStorage.getItem("id");
      let data = {
        userid,
      };
      info(data).then((res) => {
        let phone = res.data.data.phone;
        this.phone = phone.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
      });
    },
    callPhone(phoneNumber) {
      window.location.href = "tel://" + phoneNumber;
    },
    // 登录
    loginss() {
      console.log(123);
      this.$router.push("/login");
    },
    // 灵活就业订单
    lhjyorder() {
      this.$router.push("/order");
    },
    // 职业技能订单
    zyjnorder() {
      this.$router.push("/orders");
    },
    // 退出登录
    signout() {
      Dialog.confirm({
        title: "标题",
        message: "确定要退出登录吗",
      })
        .then(() => {
          // on confirm
          localStorage.setItem("login", false);
          this.login = localStorage.getItem("login");
          Toast("退出成功");
        })
        .catch(() => {
          // on cancel
        });
    },
  },
  beforeDestroy() {},
};
</script>

<style scoped lang="scss">
.meright {
  height: 100%;
  background: #ffffff;
  background-color: #ffffff;
  .bodycom_r {
    .logintop {
      width: 100%;
      height: 150px;
      //   background-color: rgb(0, 119, 255);
      text-align: center;
      color: #fff;
      background-image: url("../../assets/images/denglu_bj@2x(1).png");
      background-size: 100% 100%;
      // display: flex;
      // flex-wrap:wrap;
      // justify-content: center;
      // align-content:center;
      // align-items: center;
      .bsa {
        padding-top: 42.5px;
        .topone_r {
          width: 100%;
          font-size: 20px;
          font-weight: 700;

          height: 30px;
          // padding-top: 42.5px;
        }
        .toptwo_r {
          margin-top: 7px;
          width: 100%;
          font-size: 18px;
          height: 30px;
        }
      }
    }
    .body_r {
      width: 100%;
      box-sizing: border-box;
      padding: 0 10px;
      .order_r {
        font-size: 16px;
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid rgb(233, 233, 233);
        display: flex;
        justify-content: space-between;
        align-items: center;
        .order_l {
          // float: left;
        }
        .order_r {
          font-size: 14px;
          // float: right;
          color: rgb(158, 158, 158);
          border: 0;
          // height: 50px;
          // line-height: 50px;
        }
      }
    }
  }
}
</style>
