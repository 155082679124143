<template>
  <div class="home_warp">
    <!-- 顶部导航 -->
    <!-- <titleNav title="登录" :left-arrow="true" left-text="" /> -->
    <!-- 内容 -->
    <div class="bodycom">
      <homeLeft class="zujian" v-if="active == 'home'" />
      <meRight class="zujian" v-if="active == 'me'" />
    </div>
    <div class="nevbes" @click="service()">
      <img class="imaz" src="@/assets/images/index/kf@2x.png" alt="" />
      <van-notice-bar
        :scrollable="false"
        left-icon="volume-o"
        mode="link"
        background="#ecf9ff"
        color="#1989fa"
        >在线咨询<span style="font-size: 12px"
          >灵活就业养老一个月要交多少钱?什么时候缴费?</span
        ></van-notice-bar
      >
    </div>
    <!-- 底部导航 -->
    <van-tabbar v-model="active" active-color="#1D56EB" inactive-color="#000">
      <van-tabbar-item name="home" icon="wap-home">首页</van-tabbar-item>
      <van-tabbar-item name="me" icon="friends">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import titleNav from "@/components/conetnts/titleNav.vue";
import homeLeft from "./homeleft.vue";
import meRight from "./meright.vue";
// import floatBtn from "@/components/floatBtn.vue";
import {
  Button,
  Dialog,
  Icon,
  Toast,
  Field,
  Tabbar,
  TabbarItem,
  NoticeBar,
} from "vant";
import { indexs, getopenid, getezekinfo, info } from "@/network/index.js";
// const pushHistory = () => {
//   let state = {
//     title: '',
//     url: '/index'
//   }
//   window.history.pushState(state, state.title, state.url)
// }
export default {
  components: {
    titleNav,
    homeLeft,
    meRight,
    [Button.name]: Button,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [NoticeBar.name]: NoticeBar,
    [Toast.name]: Toast,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      //   active: "me",
      active: "home",
      openid: "123",
      type: "",
    };
  },
  created() {
    console.log("963");
    // localStorage;
    // 判断type如果type等于sb直接跳转到home页面;
    let type = this.$route.query.type;
    this.type = type;
    // sessionStorage.setItem("type", type);
    sessionStorage.setItem("type", type);
    console.log("type", type);
    if (type == "sb") {
      this.tiaozhaun();
    }

    // this.login();
    // if(sessionStorage.getItem("addse")==1){
    //   let code=this.$route.query.code
    //   this.$router.push('/projects?code='+code)
    // }
    if (sessionStorage.getItem("active") == "me") {
      this.active = "me";
    } else {
      this.active = "home";
    }
    // window.scrollTo(0,0);
    // this.codeSplicet();
    let codes = sessionStorage.getItem("id");
    if (codes == 222 || codes == "222") {
      this.login();
    }
    this.guanbi();

    this.$watch("active", function (newValue, oldValue) {
      if (this.active == "me") {
        sessionStorage.setItem("active", "me");
      } else {
        sessionStorage.setItem("active", "home");
      }
    });
  },
  mounted() {
    if (sessionStorage.getItem("addesa") == 1) {
      this.getinfor();
    }
  },

  methods: {
    tiaozhaun() {
      this.$router.push("/home");
    },
    getinfor() {
      let userid = sessionStorage.getItem("id");
      let data = {
        userid,
        // code: '061ymVll2cbHG940vPnl2TnfRC3ymVla',
        code: this.$route.query.code,
      };
      getopenid(data).then((res) => {
        // this.openid=res.data.data.openid
        console.log(res);
        if (res.data.code) {
          if (res.data.data.subscribe == 0) {
            sessionStorage.setItem("addesa", 2);
            this.$router.push("/follow");
            // this.$router.go(-1);
          } else {
            sessionStorage.setItem("addesa", 2);
            this.$router.go(-1);
          }
        } else {
          sessionStorage.setItem("addesa", 2);
          this.$router.go(-1);
        }
      });
    },
    // 客户服务
    service() {
      window.location.replace(
        // "https://work.weixin.qq.com/kfid/kfc2de3dc57f9907732"
        "http://www.sjzydrj.net/index.php/Home/News/index/id/93.html"
      );
    },
    // 获取code
    codeSplicet() {
      let codes = sessionStorage.getItem("id");
      if (codes == 222 || codes == "222") {
        this.login();
      }
    },
    // login
    login() {
      // Toast('123456')
      console.log(5);
      var _this = this;
      let url = window.location.href;
      sessionStorage.setItem("urls", url);
      console.log(url);
      let codes = this.$route.query.code;
      // let codee = sessionStorage.getItem("userAll");
      // console.log(codes);

      let data = {
        // code: '001q1bGa167fHD0O3vGa1O5UHz1q1bG5',
        code: codes,
        type: 7,
      };
      indexs(data).then((res) => {
        console.log("======index====res===========",res);
        // let id = '3646690';
        let id = res.data.data.id;
        if (res.data.code) {
          _this.id = id;
          sessionStorage.setItem("id", id);
          if (
            res.data.data.openid_ezek == undefined ||
            res.data.data.openid_ezek == null ||
            res.data.data.openid_ezek == ""
          ) {
            sessionStorage.setItem("addesa", 1);   
            window.location.href =
              "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc2bd458948e845a0&redirect_uri=http://health.sjzydrj.net&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect";
          }
        } else {
          let ids = sessionStorage.getItem("id");
          sessionStorage.setItem("id", ids);
        }
      });
    },
    guanbi() {
      let url = window.location.href;
      if (url == "http://lhjy.sjzydrj.net/") {
        setTimeout(function () {
          //这个可以关闭安卓系统的手机
          document.addEventListener(
            "WeixinJSBridgeReady",
            function () {
              WeixinJSBridge.call("closeWindow");
            },
            false
          );
          //这个可以关闭ios系统的手机
          WeixinJSBridge.call("closeWindow");
        });
      }
    },
  },
  beforeDestroy() {},
};
</script>

<style scoped lang="scss">
.home_warp {
  height: 100%;
  background: #ffffff;
  background-color: #ffffff;
  //   padding: 10px;
  //   position: relative;
  .yanzm {
    color: #0097ff;
    border-left: 1px solid rgb(134, 134, 134);
    padding-left: 10px;
  }
  .zujian {
    padding-bottom: 1.33333rem;
    //   padding-bottom: calc(1.33333rem + 30px);
  }
  .nevbes {
    width: calc(100% - 20px);
    margin-left: 10px;
    box-sizing: border-box;
    height: 40px;
    position: fixed;
    bottom: 1.34rem;
    border-radius: 20px;
    z-index: 21;
    overflow: hidden;
    .imaz {
      width: 35px;
      height: 35px;
      position: absolute;
      top: 2px;
      z-index: 20;
    }
  }
}
</style>
