<template>
  <div class="homeleft">
    <!-- 顶部导航 -->
    <!-- <titleNav title="登录" :left-arrow="true" left-text="" /> -->
    <!-- 内容 -->
    <div class="bodycoms">
      <img class="toping_l" src="@/assets/images/dong.gif" alt="" />
      <div class="title_l">灵活就业</div>
      <div class="lhjy_l">
        <div class="lhjy1" @click="home()">立即参保</div>
        <div class="lhjy2">
          <div class="lhjy2_1" @click="insurancenotice()">
            <div>灵活就业</div>
            <div>参保须知</div>
          </div>
          <div class="lhjy2_2" @click="standard()">
            <div>灵活就业</div>
            <div>缴费标准</div>
          </div>
        </div>
        <div class="lhjy3">
          <div class="lhjy3_1">
            <template>
              <wx-open-launch-weapp
                id="launch-btn"
                username="gh_5d58b08a0708"
                path="pages/home/home"
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
              >
                <script type="text/wxtag-template">
                  <div
                    style="
                      color: rgba(0,0,0,0);
                      height: 100%;
                      text-align: left;
                      padding-left: 0.53333rem;
                      line-height: 6rem;
                    "
                  >
                    社保查询
                  </div>
                </script>
              </wx-open-launch-weapp>
            </template>
          </div>

          <div class="lhjy3_2">
            <wx-open-launch-weapp
              id="launch-btn"
              username="gh_8a6c05e23f9e"
              path="pages/splash/splash/splash"
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              "
            >
              <script type="text/wxtag-template">
                <div
                  style="
                    color: rgba(0,0,0,0);
                      height: 100%;
                    text-align: left;
                    padding-left: 0.53333rem;
                    line-height: 6rem;
                  "
                >
                  医保查询
                </div>
              </script>
            </wx-open-launch-weapp>
          </div>
          <!-- <div class="lhjy3_1" @click="sbcx()">
            <div>社保查询</div>
          </div>
          <div class="lhjy3_2" @click="ybcx()">
            <div>医保查询</div>
          </div> -->
        </div>
      </div>
      
      <!-- <div class="title_l">职业技能提升</div>
      <div class="zyjn_l">
        <div class="zyjn1" @click="projects()">立即报名</div>
        <div class="zyjn2">
          <div class="zyjn2_1" @click="conditions()">
            <div>职业证书</div>
            <div>报名条件</div>
          </div>
          <div class="zyjn2_2" @click="subsidy()">
            <div>技能补贴</div>
            <div>申请条件</div>
          </div>
        </div>
      </div> -->

      <!-- 政策解读与视频讲解 -->
      <div class="zcysp">
        <div class="com_l" @click="zhengc(1)">
          <span :class="showzc == 1 ? 'spans' : 'span'">政策解读</span>
        </div>
        <div class="com_l" @click="zhengc(2)">
          <span :class="showzc == 2 ? 'spans' : 'span'">视频讲解</span
          ><span class="remen">热门</span>
        </div>
      </div>
      <policy_l v-if="showzc == 1" />
      <video_l v-if="showzc == 2" />
    </div>
  </div>
</template>

<script>
import titleNav from "@/components/conetnts/titleNav.vue";
import initWx from "@/components/weixin.config.js";
import policy_l from "./homeleft/policy.vue";
import video_l from "./homeleft/video.vue";
import {
  Button,
  Dialog,
  Icon,
  Toast,
  Field,
  Tabbar,
  TabbarItem,
  NoticeBar,
} from "vant";
import { notice, getezekinfo } from "@/network/index.js";
// import wx from "weixin-js-sdk";
// var btn = document.getElementById("launch-btn");
// btn.addEventListener("launch", function (e) {
//   Toast("success");
// });
// btn.addEventListener("error", function (e) {
//   Toast("fail", e.detail);
// });
// var config = {};

export default {
  components: {
    titleNav,
    policy_l,
    video_l,
    [Button.name]: Button,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [NoticeBar.name]: NoticeBar,
    [Toast.name]: Toast,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      showzc: 1,
      datas: [],
    };
  },
  created() {
    console.log(window.location.href,112233)
    let userid = sessionStorage.getItem("id");
    let obj = {
      userid,
      url:window.location.href,
    };
    // 请求接口，获取wx.config()配置参数
    getezekinfo(obj).then((res) => {
      console.log(res);
      if (res.data.code) {
        wx.config({
          // eslint-disable-line
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
          appId: res.data.data.appid, // 必填，公众号的唯一标识
          timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.data.noncestr, // 必填，生成签名的随机串
          signature: res.data.data.signature, // 必填，签名
          jsApiList: ["onMenuShareTimeline", "chooseImage", "previewImage"], // 必填，需要使用的JS接口列表
          openTagList: ["wx-open-launch-weapp"], // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
        });
        /* eslint-disable */
        wx.ready(function () {
          console.log("接口配置成功");
        });
        wx.error(function () {
          // alert("您的版本号不支持跳转微信小程序,请再次点击进入");
        });
      }
    });
  },
  mounted() {
    if (sessionStorage.getItem("zhengc") == 2) {
      this.showzc = 2;
    } else {
      this.showzc = 1;
    }
  },

  methods: {
    zhengc(e) {
      this.showzc = e;
      sessionStorage.setItem("zhengc", e);
    },
    //   跳转职业技能
    projects() {
      // if (
      //   sessionStorage.getItem("id") == "3646690" ||
      //   sessionStorage.getItem("id") == "3646599"
      // ) {
      //   this.$router.push("/projects");
      // } else {
      //   Toast("暂未开通" + sessionStorage.getItem("id"));
      // }
      let login = localStorage.getItem("login");
      console.log(login);
      if (login == "true") {
        this.$router.push("/projects");
      } else {
        this.$router.push("/login?id=2");
      }
    },
    // 技能补贴申请条件
    subsidy() {
      this.$router.push("/subsidy");
    },
    // 技能补贴报名条件
    conditions() {
      this.$router.push("/conditions");
    },
    // 灵活就业缴费标准
    standard() {
      this.$router.push("/standard");
    },
    // 灵活就业参保须知
    insurancenotice() {
      this.$router.push("/insurancenotice");
    },
    // 灵活参保
    home() {
      let login = localStorage.getItem("login");
      console.log(login);
      if (login == "true") {
        this.$router.push("/home");
      } else {
        this.$router.push("/login?id=1");
      }
    },
    // 社保查询
    sbcx() {
        this.$router.push("/queryss?id=1");
    },
    // 医保查询
    ybcx() {
        this.$router.push("/queryss?id=2");
      
      // this.$router.push("/queryss?id=2");
      // Toast("请在公众号便民服务中进入");
    },
  },
  beforeDestroy() {},
};
</script>


<style scoped lang="scss">
.homeleft {
  height: 100%;
  background: #ffffff;
  background-color: #ffffff;
  padding: 10px;
  .bodycoms {
    padding-bottom: 50px;
    .toping_l {
      width: 100%;
    }
    .title_l {
      font-size: 22px;
      font-weight: 700;
      line-height: 60px;
    }
    .lhjy_l {
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      .lhjy1 {
        color: #001897;
        width: 100%;
        box-sizing: border-box;
        height: 90px;
        background-image: url("../../assets/images/index/lijicanbao@2x.png");
        background-size: 100% 100%;
        padding-left: 20px;
        border-radius: 10px;
        line-height: 90px;
      }
      .lhjy2 {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        height: 100px;
        .lhjy2_1 {
          width: 42%;
          padding-top: 20px;
          padding-left: 20px;
          color: #bc2f42;
          background-image: url("../../assets/images/index/cbxz@2x.png");
          background-size: 100% 100%;
          border-radius: 10px;
        }
        .lhjy2_2 {
          width: 42%;
          padding-top: 20px;
          padding-left: 20px;
          color: #186a54;
          background-image: url("../../assets/images/index/jfbz@2x.png");
          background-size: 100% 100%;
          border-radius: 10px;
        }
      }
      .lhjy3 {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        height: 100px;

        .lhjy3_1 {
          
          color: #0c5dbc;
          position: relative;
          width: 42%;
          padding-top: 35px;
          padding-left: 20px;
          background-image: url("../../assets/images/sbcx.png");
          background-size: 100% 100%;
          border-radius: 10px;
          // padding-left: 0.53333rem;
          // line-height: 2.66667rem;
          // color: #0c5dbc;
          // text-align: left;
          // font-weight: 700;
        }
        .lhjy3_2 {
          color: #a13602;
          position: relative;
          width: 42%;
          padding-top: 35px;
          padding-left: 20px;
          background-image: url("../../assets/images/ybcx.png");
          background-size: 100% 100%;
          border-radius: 10px;
          // color: #a13602;
          // text-align: left;
          // padding-left: 0.53333rem;
          // line-height: 2.66667rem;
          // font-weight: 700;
        }
      }
    }
    .zyjn_l {
      font-size: 20px;
      font-weight: 700;
      .zyjn1 {
        color: #186a54;
        width: 100%;
        box-sizing: border-box;
        height: 90px;
        background-image: url("../../assets/images/index/libm@2x.png");
        background-size: 100% 100%;
        padding-left: 20px;
        border-radius: 10px;
        line-height: 90px;
      }
      .zyjn2 {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        height: 100px;
        .zyjn2_1 {
          width: 42%;
          padding-top: 20px;
          padding-left: 20px;
          color: #bc2f42;
          background-image: url("../../assets/images/index/bmtj@2x.png");
          background-size: 100% 100%;
          border-radius: 10px;
        }
        .zyjn2_2 {
          width: 42%;
          padding-top: 20px;
          padding-left: 20px;
          color: #da6f3a;
          background-image: url("../../assets/images/index/sqtj@2x.png");
          background-size: 100% 100%;
          border-radius: 10px;
        }
      }
    }
    .zcysp {
      margin-top: 20px;
      font-size: 16px;
      font-weight: 700;
      height: 40px;
      .com_l {
        display: inline-block;
        width: 50%;
        text-align: center;

        .span {
          padding: 15px 0;
        }
        .spans {
          border-bottom: 1px solid #f00;
          padding: 15px 0;
        }
        .remen {
          background-color: red;
          color: #fff;
          font-size: 12px;
          padding: 2px 4px;
          border-radius: 3px;
          margin-left: 2px;
        }
      }
    }
  }
}
</style>
